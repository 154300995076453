@import "./variables";

.equipment {
  .item {
    position: relative;
    padding: 0.125em 0.5em;
    user-select: none;
    color: $color-disabled;
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    &:nth-child(odd) {
      background-color: $color-background-opaque;
    }

    &.equipped {
      color: $color-primary;
    }

    .description {
      padding-left: 1em;
      font-size: $size-xs;
    }
  }
}
