@import "./variables";

.message {
  font-size: $size-sm;
  &:first-of-type {
    margin-bottom: 0;
  }
  &:nth-of-type(2) {
    margin-top: 0;
  }
  &:last-of-type {
    margin: 1em 0;
    font-size: $size-md;
  }
  padding: 0 1em;

  &.dead {
    padding-right: 50%;
  }
}
