$color-primary: hsl(35, 50%, 55%);
$color-primary-off: hsl(35, 50%, 50%);
$color-background: hsl(35, 35%, 9%);
$color-disabled: hsl(35, 35%, 35%);
$color-background-opaque: hsla(35, 35%, 40%, 0.075);
$size-xs: 12px;
$size-sm: 14px;
$size-md: 18px;
$size-lg: 24px;
$width: 500px;
