@import "./variables";

.App {
  display: flex;
  flex-direction: column;
  max-width: $width;
  margin: auto;
  height: 100vh;
  font-size: $size-md;
  background-color: $color-background;
  color: $color-primary;

  button,
  input,
  select,
  textarea {
    font-family: inherit;
    font-size: $size-md;
  }

  .header {
    margin-bottom: auto;
    background-color: $color-background-opaque;
    z-index: 1;

    .name-form {
      padding: 1em;
    }
  }

  .button-section {
    display: flex;
    justify-content: space-between;
    padding: 1em;

    .button {
      cursor: pointer;
      user-select: none;
      padding: $size-md 0;
      width: calc(50% - 0.5em);
      font-size: $size-md;
      border: 4px solid transparent;
      text-align: center;
      vertical-align: middle;
      color: $color-primary;
      background-color: $color-background-opaque;

      &:hover {
        border: 4px solid $color-primary;
      }
    }
  }
}
