@import "./variables";

.merchant-inventory {
  font-size: $size-sm;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 22em;

  .item {
    display: flex;
    justify-content: space-between;
    padding: 0.5em;
    height: 2.5em;
    width: 44%;
    border: 4px solid transparent;
    user-select: none;
    font-size: $size-xs;

    &:nth-child(even) {
      background-color: $color-background-opaque;
    }

    &:not(.disabled):hover {
      border: 4px solid $color-primary;
      border-top: 4px solid $color-primary;
      cursor: pointer;
    }

    &.disabled {
      color: $color-disabled;
    }

    .info {
      text-align: end;

      .description {
        visibility: visible;
        font-size: $size-xs;
      }
    }
  }
}
