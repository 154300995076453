@import "./variables";

.hero-status {
  user-select: none;
  color: $color-background;
  background-color: $color-primary;

  .effect {
    padding: 0.125em 0.5em;

    &:nth-child(even) {
      background-color: $color-primary-off;
    }
  }
}
