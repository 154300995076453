.health {
  display: flex;
  flex-direction: row;
  align-items: center;

  .health-count {
    margin-right: 0.5em;
  }

  .health-bar {
    flex-grow: 2;
    height: 1em;
    background-color: hsl(0, 55%, 42%);

    .hp {
      height: 1em;
      background-color: hsl(120, 55%, 34%);
      transition: width 0.2s;
    }
  }
}
