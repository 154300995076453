@import "./variables";

body {
  margin: 0;
  font-family: "Kode Mono", monospace;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @keyframes white-black-shift {
    from {
      background-color: #fff;
    }

    to {
      background-color: #000;
    }
  }

  &::after {
    z-index: -1;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100vh;
    transform: rotate(180deg);
    background-image: url("../public/film-grain.jpeg");
    background-size: 100% 100%;
    background-blend-mode: difference;
    content: "";
    pointer-events: none;
    animation: white-black-shift 120s infinite linear alternate;
  }
}
