@import "./variables";

.character-sheet {
  max-width: $width;
  padding: 1em;
  color: $color-primary;
  font-size: $size-sm;
  background-color: $color-background-opaque;
  z-index: 1;

  .character-header {
    display: flex;
    justify-content: space-between;

    .hero-name {
      font-size: $size-lg;
    }

    .level-box {
      text-align: end;
    }
  }

  .character-body {
    display: flex;
    justify-content: space-between;
    padding-top: 0.5em;

    .status {
      margin-left: auto;
      text-align: end;
    }
  }
}
