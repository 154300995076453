@import "./variables";

@keyframes grow {
  0% {
    width: 0vw;
  }
  100% {
    width: 100vw;
  }
}

@keyframes fade {
  0% {
    opacity: 100%;
  }
  100% {
    opacity: 0;
  }
}

.toast {
  text-align: center;
  margin: auto;
  white-space: nowrap;
  overflow: hidden;
  animation: grow 0.2s ease-in, fade 1.5s 1.2s;
  color: $color-background;
  background-color: $color-primary;
}
